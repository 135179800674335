import clsx from 'clsx'
import React, { Children } from 'react'
import useMediaQuery from '../hooks/useMediaQuery'

import styles from './Flex.module.sass'

export function Flex(props: {
	children: React.ReactNode
	itemsPerRow?: number
	mobileItemsPerRow?: number
	spacing?: number
	marginTop?: number
	marginBottom?: number
	justifyContent?: 'center' | 'end' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around'
	alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start'
}) {
	const isMobile = useMediaQuery('(max-width: 768px)')

	const marginTop = props.marginTop ?? 0
	const marginBottom = props.marginBottom ?? 0
	const perRow = isMobile ? props.mobileItemsPerRow : props.itemsPerRow
	const itemWidth = perRow ? 100 / perRow : null
	const space = props.spacing ? props.spacing / 2 : 0

	return (
		<div
			className={styles.wrapper}
			style={{
				width: `calc(100% + ${space * 2}px)`,
				marginLeft: `-${space}px`,
				'--Flex-margin-top': `${marginTop - space}px`,
				marginBottom: `${marginBottom - space}px`,
			}}>
			<div
				className={styles.inner}
				style={{
					justifyContent: props.justifyContent ?? 'flex-start',
					alignItems: props.alignItems ?? 'flex-start',
				}}>
				{Children.map(Children.toArray(props.children), (child) => (
					<div
						className={clsx(styles.item)}
						style={
							itemWidth
								? { width: `calc(${itemWidth}% - ${space * 2}px)`, margin: `${space}px` }
								: { width: 'auto', margin: `0 ${space}px` }
						}>
						{child}
					</div>
				))}
			</div>
		</div>
	)
}
