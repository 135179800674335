import clsx from 'clsx'
import type { PlantCategoryResult } from '../data/fragments/PlantCategory'
import type { PlantSubcategoryResult } from '../data/fragments/PlantSubcategory'
import { useCategoryFilter } from '../utils/CategoryFilterContext'
import styles from './CategoryFilter.module.sass'

export const CategoryFilter = (props: {
	categories: PlantCategoryResult[]
	subcategories: PlantSubcategoryResult[]
}) => {
	const categoryFilter = useCategoryFilter()
	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				{props.categories.map((category, index) => {
					const subcategories = props.subcategories.filter((subcategory) => {
						return subcategory.parentCategory?.id === category.id
					})
					if (category.localesByLocale?.name) {
						return (
							<li
								key={index}
								className={clsx(
									categoryFilter.getOpenCategories().includes(category.id) && styles.active
								)}>
								<button
									onClick={() => {
										const catId = String(category.id)
										categoryFilter.setOpenedCats((old) => {
											if (catId && !categoryFilter.getOpenCategories().includes(catId)) {
												if (old.indexOf(catId) === -1) {
													return [...old, catId]
												}
											} else {
												return old.filter((v) => v !== catId)
											}
											return old
										})
									}}>
									{category.localesByLocale?.name}
									{subcategories.length > 0 && <span></span>}
								</button>
								{subcategories.length > 0 && (
									<ul>
										{subcategories.map((subcategory, index) => {
											return (
												<li
													key={index}
													className={clsx(
														categoryFilter.getCategoryFilter().includes(subcategory.id) &&
															styles.active
													)}>
													<button
														onClick={() => {
															const catId = String(subcategory.id)
															categoryFilter.setCategoryFilter((old) => {
																if (catId && !categoryFilter.getCategoryFilter().includes(catId)) {
																	if (old.indexOf(catId) === -1) {
																		return [...old, catId]
																	}
																} else {
																	return old.filter((v) => v !== catId)
																}
																return old
															})
														}}>
														<span></span>
														{subcategory.localesByLocale?.name}
													</button>
												</li>
											)
										})}
										<li
											className={clsx(
												categoryFilter.getCategoryFilter().includes(category.id) && styles.active
											)}>
											<button
												onClick={() => {
													const catId = String(category.id)
													categoryFilter.setCategoryFilter((old) => {
														if (catId && !categoryFilter.getCategoryFilter().includes(catId)) {
															if (old.indexOf(catId) === -1) {
																return [...old, catId]
															}
														} else {
															return old.filter((v) => v !== catId)
														}
														return old
													})
												}}>
												<span></span>
												{`All ${category.localesByLocale?.name}`}
											</button>
										</li>
									</ul>
								)}
							</li>
						)
					}
				})}
			</ul>
		</div>
	)
}
