import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { Container } from '../app/components/Container'
import { Nav } from '../app/components/Nav'
import { adminZeus } from '../app/data/thunder'

import styles from '../app/styles/Home.module.sass'

import { CardPlant } from '../app/components/CardPlant'
import { CategoryFilter } from '../app/components/CategoryFilter'
import { Flex } from '../app/components/Flex'
import { Footer } from '../app/components/Footer'
import { Search } from '../app/components/Search'
import { scalarResolver } from '../app/data/createScalarResolvers'
import { PlantCategoryFragment } from '../app/data/fragments/PlantCategory'
import { PlantProfileFragment } from '../app/data/fragments/PlantProfileFragment'
import { PlantSubcategoryFragment } from '../app/data/fragments/PlantSubcategory'
import useMediaQuery from '../app/hooks/useMediaQuery'
import { useCategoryFilter } from '../app/utils/CategoryFilterContext'
import { filterNonEmpty } from '../app/utils/filterNonEmpty'
import { OrderDirection, PlantProfileState } from '../generated/content'
import { serverPropsHandler } from '../libs/next/api/handlers'

export default function Home(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const isMobile = useMediaQuery('(max-width: 900px)')
	const plantProfiles = props.data.listPlantProfile
	const categories = props.data.listPlantCategory
	const subCategories = props.data.listPlantSubcategory
	const categoryFilter = useCategoryFilter()

	const filteredPlantProfiles =
		categoryFilter.getCategoryFilter().length > 0
			? plantProfiles.filter((item) => {
					const selectedCats = categoryFilter.getCategoryFilter()
					let isIn = false
					item.activeRevision?.categories.forEach((cat) => {
						if (selectedCats.includes(String(cat.id))) {
							isIn = true
							return true
						}
					})
					item.activeRevision?.subCategories.forEach((subCat) => {
						if (selectedCats.includes(String(subCat.id))) {
							isIn = true
							return true
						}
					})

					return isIn
			  })
			: plantProfiles

	return (
		<div>
			<Head>
				<title>Planteebase – plant lover&apos;s ultimate database</title>
				<meta
					name="description"
					content="Planteebase, a plant database for gardeners, botanists and plant enthusiasts. Full of information on a wide range of plant species and their care requirements."
				/>
				<link rel="icon" href="/favicon-32x32.png" />
			</Head>
			<Nav />
			<Container marginTop={50} view="wide">
				<div className={styles.listing}>
					{isMobile && <Search isMobile />}
					<CategoryFilter categories={categories} subcategories={subCategories} />
					<Flex justifyContent="flex-start" spacing={20} itemsPerRow={4} mobileItemsPerRow={2}>
						{filteredPlantProfiles.map((item, index) => {
							const images = item.activeRevision?.images?.items.sort(
								(a, b) => Number(b.image?.isThumbnail) - Number(a.image?.isThumbnail)
							)
							return (
								<CardPlant
									key={index}
									images={images?.map((item) => item.image).filter(filterNonEmpty) ?? []}
									title={item.activeRevision?.localesByLocale?.name ?? ''}
									user={{
										name: (item.author?.nick || item.author?.email) ?? '',
										id: String(item.author?.id),
										image: item.author?.avatar,
									}}
									likes={0}
									link={`/profile/${item.author?.id}/${item.id}`}
									plantProfile={item}
								/>
							)
						})}
					</Flex>
				</div>
			</Container>
			<Footer />
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context: GetServerSidePropsContext) => {
	const locale = context.locale as string
	console.log(locale)
	const data = await adminZeus('query', { scalars: scalarResolver })({
		listPageLocale: [{}, { title: true }],
		listPlantCategory: [{}, PlantCategoryFragment(locale)],
		listPlantSubcategory: [{}, PlantSubcategoryFragment(locale)],
		listPlantProfile: [
			{
				orderBy: [{ activeRevision: { createdAt: OrderDirection.asc } }],
				filter: {
					activeRevision: {
						images: { items: { not: null } },
					},
					state: {
						eq: PlantProfileState.published,
					},
				},
			},
			PlantProfileFragment(locale),
		],
	})

	return { props: { data } }
})
